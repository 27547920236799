import { mapActions } from 'vuex'
import addCartChangeNum from "../components/dialog/addCartChangeNum.vue" //添加采购购物车弹框
import addPurchaseOrder from '../components/dialog/addPurchaseOrder.vue' //采购购物车弹框
import { ElMessage } from 'element-plus'
export default{
	data(){
		return{
			cartGoods:[],  //加入购物车的商品
			purchaseList: [], // 平台代购购物车
			changeNumShow:false, //添加弹框的隐藏显示
			purchaseCartShow:false, //采购购物车的隐藏显示
			activeRow:{ //当前选中的数据
				row:{},
				info:{},
			},
		}
	},
	components:{
		addCartChangeNum,
		addPurchaseOrder,
	},
	methods:{
		...mapActions('purchaseMudule',[
			'purchaseCartListCart', //采购购物车列表
			'purchaseCart',  //删除购物车商品
		]),
		getCartList(){ //获取购物车商品
			this.purchaseCartListCart({
				inquiryId:this.inquiryId,
			}).then(res => {
				let {data,code} = res.data;
				if(code === '0'){
					this.cartGoods = data;
				}
			})
			this.purchaseCartListCart({
				inquiryId:this.inquiryId,
				type: '0'
			}).then(res => {
				let {data,code} = res.data;
				if(code === '0'){
					this.purchaseList = data;
				}
			})
			
		},
		addSuccess(){ //添加成功
			this.getCartList();
		},
		showPurchase(){
			if(this.cartGoods.length || this.purchaseList.length){
				this.purchaseCartShow = true;
			}else{
				ElMessage({
					type:'warning',
					message:'请选择要生成采购单的商品',
				})
			}
		},
		deleteCart(row){ //删除购物车商品
			this.purchaseCart(row.id).then(res => {
				let {code,data} = res.data;
				if(code === '0'){
					ElMessage({
						type:'success',
						message:'删除成功',
					})
					//刷新购物车列表
					this.getCartList();
				}
			})
		},
		createSuccess(){ //生成采购单成功
			//刷新详情页面数据
			this.$emit('bjSuccess');
		}
	},
	created(){
		this.getCartList();
	}
}