<template>
	<el-dialog v-model="show" :show-close="false" width="1300px" top="10vh" @open="open" :destroy-on-close="true"
		@close="closeDialog">
		<div class="i">
			<div class="title-box">
				<div class="title-right-box" @click="closeDialog">
					<p class="iconfont icon-shang"></p>
					<span>收起采购单</span>
				</div>
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="直接购买" name="0">
					<div class="i-content">
						<div class="i-title-left">
							<el-button @click="createOrder" type="primary" size="mini">生成采购单</el-button>
						</div>
						<!-- 商品信息 -->
						<lida-table height="calc(100% - 40px)" :pagination="false" :cutHeight="getHeight" :data="tableData"
							border ref="table">
							<lida-table-column label="产品信息">
								<template #default="scope">
									<div class="g-info">
										<div class="g-icon">
											<img :src="scope.row.src" />
										</div>
										<div class="g-right">
											<div class="g-r-title">
												{{ scope.row.productName }}
												<div class="label" :title="scope.row.brandName">{{ scope.row.brandName }}
												</div>
											</div>
											<div class="g-r-data">
												{{ scope.row.barCode || '无' }}
												<span>|</span>
												{{ scope.row.seriesName || '无' }}
												<span>|</span>
												{{ scope.row.skuNumber || '无' }}
											</div>
										</div>
									</div>
								</template>
							</lida-table-column>
							<lida-table-column label="订货号" prop="articleNo" />
							<lida-table-column label="面价" prop="guidePrice" />
							<lida-table-column label="报价数量" prop="qty" />
							<lida-table-column label="采购数量" prop="purchaseQty" :width="240">
								<template #default="scope">
									<el-input-number @change="num => numChange(num, scope.row)"
										v-model="scope.row.purchaseQty" :min="1" :max="99999999" />
								</template>
							</lida-table-column>
							<lida-table-column label="采购单价" prop="purchasePrice" />
							<lida-table-column label="发货地区" prop="shipArea" />
							<lida-table-column label="渠道" prop="channel" />
							<lida-table-column label="是否过保" prop="isExpire" />
							<lida-table-column label="操作" fixed="right">
								<template #default="scope">
									<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
								</template>
							</lida-table-column>
						</lida-table>
					</div>
				</el-tab-pane>
				<el-tab-pane label="平台购买" name="1">
					<div class="i-content">
						<div class="i-title-left">
							<el-button @click="platformPurchase" type="primary" size="mini">平台代购</el-button>
						</div>
						<!-- 商品信息 -->
						<lida-table height="calc(100% - 50px)" :pagination="false" :cutHeight="getHeight" :data="tableData"
							border ref="table2">
							<lida-table-column label="产品信息">
								<template #default="scope">
									<div class="g-info">
										<div class="g-icon">
											<img :src="scope.row.src" />
										</div>
										<div class="g-right">
											<div class="g-r-title">
												{{ scope.row.productName }}
												<div class="label" :title="scope.row.brandName">{{ scope.row.brandName }}
												</div>
											</div>
											<div class="g-r-data">
												{{ scope.row.barCode || '无' }}
												<span>|</span>
												{{ scope.row.seriesName || '无' }}
												<span>|</span>
												{{ scope.row.skuNumber || '无' }}
											</div>
										</div>
									</div>
								</template>
							</lida-table-column>
							<lida-table-column label="订货号" prop="articleNo" />
							<lida-table-column label="面价" prop="guidePrice" />
							<lida-table-column label="报价数量" prop="qty" />
							<lida-table-column label="采购数量" prop="purchaseQty" :width="240">
								<template #default="scope">
									<el-input-number @change="num => numChange(num, scope.row)"
										v-model="scope.row.purchaseQty" :min="1" :max="99999999" />
								</template>
							</lida-table-column>
							<lida-table-column label="采购单价" prop="purchasePrice" />
							<lida-table-column label="发货地区" prop="shipArea" />
							<lida-table-column label="渠道" prop="channel" />
							<lida-table-column label="是否过保" prop="isExpire" />
							<lida-table-column label="操作" fixed="right">
								<template #default="scope">
									<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
								</template>
							</lida-table-column>
						</lida-table>
					</div>
				</el-tab-pane>
			</el-tabs>
			<!-- <div class="i-title">
				<div class="i-title-left">
					<el-button @click="createOrder" type="primary" size="mini">生成采购单</el-button>
				</div>
				<div class="i-title-right" @click="closeDialog">
					<p class="iconfont icon-shang"></p>
					<span>收起采购单</span>
				</div>
			</div> -->
		</div>
	</el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapActions } from 'vuex'
export default {
	data() {
		return {
			activeName: '0',
			tableData: []
		}
	},
	props: {
		modelValue: Boolean,
		data: Array,
		inquiryId: String,
	},
	created() {
		// this.tableData = this.data
		// console.log(this.data);
		this.activeName = '0'
		if (this.tableData.length === 0) {
			this.handleClick('0')
		}
		const params = {
			inquiryId: this.inquiryId,
			type: this.activeName
		}
		// this.tableData = []
		this.purchaseCartListCart(params).then(res => {
			let { data, code } = res.data;
			if (code === '0') {
				this.tableData = data;
			}
		})
	},
	methods: {
		...mapActions('purchaseMudule', [
			'purchaseCartCreatePurchase',  //生成采购单
			'purchaseCartUpdate',   //修改购物车商品信息
			'purchaseCartListCart', // 采购购物车列表 查列表
			'purchaseCartCreatePurchaseAdmin', // 生成采购单(平台采购)
		]),
		closeDialog() { //关闭弹框
			this.$emit('update:modelValue', false);
		},
		deleteRow(row) { //删除row的事件
			this.$emit("delete", row);
			// console.log(row);
			// this.handleClick(this.activeName)
			this.tableData.forEach((v, i) => {
				// console.log(v,i);
				this.tableData.splice(i, 1)
			});
		},
		close() { //关闭弹框
			this.$emit('update:modelValue', false);
		},
		createOrder() {
			var data = JSON.parse(JSON.stringify(this.tableData));
			// console.log(this.tableData);
			if (data.length) {
				this.purchaseCartCreatePurchase(this.inquiryId).then(res => {
					let { code, data } = res.data;
					if (code === '0') {
						ElMessage({
							type: 'success',
							message: '生成采购单成功',
						})
						this.close();
						this.$emit('success');
					}
				})
			} else {
				ElMessage({
					type: 'warning',
					message: '请选择要生成采购单的商品',
				})
			}
		},
		numChange(num, row) { //改变数量的事件
			this.purchaseCartUpdate({
				...row,
				purchaseQty: num,
			}).then(res => {
				let { code, data } = res.data;
				if (code === '0') { }
			})
		},
		// 切换 tab
		handleClick(val) {
			const params = {
				inquiryId: this.inquiryId,
				type: this.activeName
			}
			// this.tableData = []
			this.purchaseCartListCart(params).then(res => {
				let { data, code } = res.data;
				if (code === '0') {
					this.tableData = data;
				}
			})
		},
		// 平台购买
		platformPurchase() {
			// console.log(this.tableData)
			const list = JSON.parse(JSON.stringify(this.tableData))
			const params = {
				inquiryId: this.inquiryId,
				purchaseCartItemVOList: list
			}
			console.log(params, list, '----')
			if (this.tableData.length) {
				this.purchaseCartCreatePurchaseAdmin(params).then(res => {
					let { code, data } = res.data;
					if (code === '0') {
						ElMessage({
							type: 'success',
							message: '生成平台代购成功',
						})
						this.close();
						this.$emit('success');
					}
				})
			} else {
				ElMessage({
					type: 'warning',
					message: '请选择要生成平台代购的商品',
				})
			}
		}
	},
	computed: {
		getHeight() {
			return document.body.clientHeight / 8;
		},
	},
	watch: {
		modelValue(v) {
			this.show = v;
			this.activeName = '0'
			if (this.tableData.length === 0) {
				this.handleClick('0')
			}
			const params = {
				inquiryId: this.inquiryId,
				type: this.activeName
			}
			// this.tableData = []
			this.purchaseCartListCart(params).then(res => {
				let { data, code } = res.data;
				if (code === '0') {
					this.tableData = data;
				}
			})
		},
	},
}
</script>

<style scoped lang="less">
.i {
	font-size: 14px;
	padding: 10px 20px 20px 20px;
	position: relative;
	height: 500px;
	overflow-y: auto;

	.title-box {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 50;

		.title-right-box {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.i-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;

		.i-title-right {
			color: #999999;
			display: flex;
			align-items: center;
			cursor: pointer;

			p {
				font-size: 14px;
				margin-right: 4px;
			}
		}
	}

	.i-title-left {
		margin-bottom: 10px;
	}

	.i-content {
		height: 70vh;

		.g-info {
			display: flex;
			// overflow-x: auto;
			align-items: center;
			justify-content: space-between;

			.g-icon {
				img {
					width: 60px;
					height: 60px;
				}
			}

			.g-right {
				flex: 1;
				box-sizing: border-box;
				padding-left: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.g-r-title {
					font-size: 14px;
					display: flex;

					.label {
						max-width: 100px;
						background: @dh-color;
						border-radius: 0 10px 10px 10px;
						color: #FFFFFF;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 12px;
						padding: 0 8px;
						margin-left: 10px;
						height: 24px;
						box-sizing: border-box;
					}
				}

				.g-r-data {
					color: #666666;
					font-size: 12px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					span {
						margin: 0 10px;
					}
				}
			}
		}
	}

	.iu {
		width: 100%;
		height: 100%;
	}
}</style>
