<template>
  <div class="o">
    <div class="o-title">
      <div class="o-t-left">
        总报价数量<span>{{ company.length }}</span>个
      </div>
      <!-- v-if="info.inquiryRequestVO.documentState === 'COMPLETE_QUOTE'" -->
      <div style="position: absolute; left: 10%">
        <el-button style="margin-right: 16px" @click="dialog = true">采购记录
        </el-button>
        <el-dialog v-model="dialog">
          <div class="a">
            <div class="a-title">查看采购记录</div>
            <div style="height: 1000px; overflow: auto">
              <el-table :data="content">
                <el-table-column type="index" width="55" label="序号" />
                <el-table-column property="documentDate" label="时间" />
                <el-table-column property="sellerEnterpriseName" label="被采购公司" />
                <el-table-column property="contractNum" label="型号" />
                <el-table-column property="purchaseQty" label="采购数量" />
              </el-table>
            </div>
          </div>
        </el-dialog>
      </div>
      <div class="m-r-cart" @click="showPurchase">
        <div class="c-cart-icon">
          <i class="iconfont icon-gouwuche"></i>
          <div class="dot" v-if="cartGoods.length">
            <p>{{ cartGoods.length }}</p>
          </div>
        </div>
      </div>
    </div>
    <lida-table :pagination="false" :cutHeight="0" :data="data" border ref="table">
      <!-- <lida-table-column label="订货号" prop="articleNo" :min-width="150" /> -->
      <el-table-column type="index" label="序号" width="50" align="center" fixed />
      <lida-table-column label="产品信息">
        <template #default="scope">
          <div class="g-info">
            <div class="g-icon">
              <img :src="scope.row.src" />
            </div>
            <div class="g-right">
              <div class="g-r-title">
                <el-tag>{{ scope.row.brandName }}</el-tag>
              </div>
              <div class="g-r-data">
                {{ scope.row.productName || "无" }}
                <span>|</span>
                {{ scope.row.barCode || "无" }}
                <span>|</span>
                {{ scope.row.articleNo || "无" }}
              </div>
            </div>
          </div>
        </template>
      </lida-table-column>
      <!-- <lida-table-column label="品牌" :min-width="180"  align="center">
        <template #default="scope">
          <el-tag>{{scope.row.brandName}}</el-tag>
        </template>
      </lida-table-column> -->
      <!-- <lida-table-column label="商品名称" prop="productName" :min-width="140" align="center" />
      <lida-table-column label="型号" prop="barCode" :min-width="140" align="center" />

      <lida-table-column label="订货号" prop="articleNo" :min-width="140" align="center"/> -->
      <lida-table-column label="面价" prop="guidePrice">
        <template #default="scope">
          <div>
            ￥{{ scope.row.enterpriseVOList.map((v) => v.guidePrice).join() }}
          </div>
        </template>
      </lida-table-column>
      <lida-table-column label="数量" prop="skuQty" :min-width="60" />
      <!-- <lida-table-column label="备注" prop="" /> -->
      <lida-table-column label="最高价" prop="maxPurchasePrice">
        <template #default="scope">
          <div>￥{{ scope.row.maxPurchasePrice }}</div>
        </template>
      </lida-table-column>
      <lida-table-column label="最低价" prop="minPurchasePrice">
        <template #default="scope">
          <div>￥{{ scope.row.minPurchasePrice }}</div>
        </template>
      </lida-table-column>
      <lida-table-column :min-width="100" v-for="item in company" :key="item.sellerEnterpriseId">
        <template #header>
          <div class="c-t-head-box">
            {{ item.sellerEnterpriseName }}
            <el-popover v-if="item.agentList && item.agentList.length" placement="left" width="400" trigger="click">
              <div class="h-tree-box">
                <p class="h-title">代理品牌</p>
                <!-- <el-tree
                  :data="item.agentList"
                  default-expand-all
                  node-key="brandName"
                  ref="tree"
                /> -->
                <p v-for="(v, i) in item.agentList" :key="i">
                  {{ v.brandName }}
                </p>
              </div>
              <template #reference>
                <span style="margin-top: 10px">
                  <span class="label" style="
                      background: #ecf5ff;
                      color: #409eff;
                      border: 1px solid #d9ecff;
                      margin-right: 10px;
                    " v-if="item.isAgent">
                    {{ item.isAgent ? "代理" : "" }}</span>
                  <span class="label" style="
                      background: #f0f9eb;
                      color: #67c23a;
                      border: 1px solid #e1f3d8;
                      margin-right: 10px;
                    " v-if="item.proprietaryFlag === '1'">
                    {{ item.proprietaryFlag === "1" ? "自营" : "" }}</span>
                  <span class="label" style="
                      background: #fdf6ec;
                      color: #e6a23c;
                      border: 1px solid #faecd8;
                    " v-if="item.authFlag === '1'" title="平台供应商，客户资源共享合作伙伴">{{ item.authFlag === "1" ? "合作伙伴" : ""
                    }}</span>
                </span>
              </template>
            </el-popover>

            <el-button type='text' size="mini" @click="() => clickExportOffer(item)">导出报价单</el-button>
            <!-- <span v-if="item.agentList&&item.agentList.length" class="isAgent-text">Ⅴ</span> -->
            <!--            <el-popover-->
            <!--                v-if="item.agentList&&item.agentList.length"-->
            <!--                placement="left"-->
            <!--                width="400"-->
            <!--                trigger="click"-->
            <!--            >-->
            <!--              <template #reference>-->
            <!--                <span v-if="item.agentList&&item.agentList.length" class="isAgent-text">Ⅴ</span>-->
            <!--              </template>-->
            <!--            </el-popover>-->
          </div>
        </template>
        <template #default="scope">
          <span v-if="item[scope.row.skuId]">
            <div :class="[
              'c',
              getRowClass(
                item[scope.row.skuId].purchasePrice,
                scope.row.minPurchasePrice,
                scope.row.maxPurchasePrice
              ),
            ]">
              <!-- <div class="c-item"><span>采购单价:</span>￥{{item[scope.row.skuId].purchasePrice}}</div> -->
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <span class="c-item" style="
                    color: #d84146;
                    font-weight: bold;
                    font-size: 20px;
                    display: inline-block;
                  ">￥{{ item[scope.row.skuId].salesPrice }}</span>
                <span @click="openModal(item[scope.row.skuId].itemList)" v-if="item[scope.row.skuId].itemList"><i
                    class="el-icon-s-order" style="font-size: 24px; cursor: pointer"></i></span>
              </div>
              <el-row>
                <div class="c-item">
                  <span>数量:</span>{{ item[scope.row.skuId].qty }}
                </div>
                <div class="c-item" style="margin-left: 10px">
                  <span>渠道:</span>{{ item[scope.row.skuId].channel }}
                </div>
              </el-row>
              <el-row>
                <div class="c-item">
                  <span>地区:</span>{{ item[scope.row.skuId].shipArea }}
                </div>
                <div class="c-item" style="margin-left: 10px">
                  <span>折扣:</span>{{ item[scope.row.skuId].salesDis }}%
                </div>
                <span class="c-item c-btn">
                  <!-- v-if="info.inquiryRequestVO.documentState === 'COMPLETE_QUOTE'" -->
                  <i class="iconfont icon-gouwuche" @click="addCart(item[scope.row.skuId], scope.row, item)"></i>
                </span>
              </el-row>
              <el-row>
                <div class="c-item">
                  <span>报价人:</span>{{ item[scope.row.skuId].sellerName }}
                </div>
              </el-row>
              <el-row>
                <div class="c-item">
                  <span>报价方式:</span>{{
                    item[scope.row.skuId].isNewest === "0"
                    ? "自动报价"
                    : "人工报价"
                  }}
                </div>
              </el-row>
              <el-row>
                <div class="c-item">
                  <span>报价时间:</span>{{ item[scope.row.skuId].quoteTime }}
                </div>
              </el-row>
              <!-- <div class="c-item"><span>利润点数:</span>{{item[scope.row.skuId].profitSite}}</div>
								<div class="c-item"><span>面价点数:</span>{{item[scope.row.skuId].guideSite}}</div>
								<div class="c-item"><span>面价:</span>{{item[scope.row.skuId].guidePrice}}</div>
								<div class="c-item"><span>采购折扣 ( % ):</span>{{item[scope.row.skuId].purchaseDis}}</div> -->

              <!-- <div class="c-item"><span>实际利润:</span>{{item[scope.row.skuId].profitC}}</div> -->
            </div>
          </span>
          <div v-else>
            <el-empty :image-size="50" description="未报价"></el-empty>
          </div>
        </template>
      </lida-table-column>
      <!-- <lida-table-column label="操作" fixed="right">
					<template #default="scope">
						<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
					</template>
				</lida-table-column> -->
    </lida-table>
    <el-dialog v-model="dialogTableVisible" title="同一企业下其他员工报价详情" width="60%">
      <el-table :data="gridData">
        <el-table-column property="salesPrice" label="价格" align="center" />
        <el-table-column property="qty" label="数量" align="center" />
        <el-table-column property="channel" label="渠道" align="center" />
        <el-table-column property="shipArea" label="地区" align="center" />
        <el-table-column property="salesDis" label="折扣" align="center" />
        <el-table-column property="sellerName" label="报价人" align="center" />
        <el-table-column property="name" label="报价方式" align="center">
          <template v-slot="scope">
            {{ scope.row.isNewest === "0" ? "自动报价" : "人工报价" }}
          </template>
        </el-table-column>
        <el-table-column property="quoteTime" label="报价时间" align="center" />
        <el-table-column property="quoteTime" label="操作" align="center">
          <template v-slot="scope">
            <span class="c-item c-btn">
              <i class="iconfont icon-gouwuche" style="color: #e4393c" @click="addCart1(scope.row)"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <!-- 添加到采购购物车弹框 -->
  <add-cart-change-num v-model="changeNumShow" @success="addSuccess" :info="activeRow" />
  <!-- 采购购物车 -->
  <add-purchase-order v-model="purchaseCartShow" :data="cartGoods" @delete="deleteCart" @success="createSuccess"
    :inquiryId="inquiryId" />
  <offer-info-dialog ref="offerInfo" type="1" v-model="offerInfoVisible" :data="offerInfoData" :quoteInfos="quoteInfos"
    @success="() => { }" />
</template>

<script>
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import purchaseCartMixins from "../../mixins/purchaseCart.js";
import offerInfoDialog from "../../../mysaleMudule/components/dialog/offerInfo.vue"; // 导出报价单弹窗
import moment from "moment";

import { mapActions } from "vuex";
import { ElMessage } from "element-plus";
import {
  handleTreeData, //处理树形数据
} from "@/common/js/common.js";

export default {
  data() {
    return {
      offerInfoData: [],
      data: [], //表格数据
      company: [],
      content: [],
      total: 0,
      dialog: false,
      dialogTableVisible: false,
      gridData: [],
      quoteInfos: {},
      offerInfoVisible: false,
    };
  },
  components: {
    offerInfoDialog,
  },
  props: ["info", "inquiryId"],
  mixins: [purchaseCartMixins],
  watch: {
    info(v) {
      this.data = v.forEnterprise || [];
    },
  },
  methods: {
    ...mapActions("purchaseMudule", [
      //'inquiryPurchaseInfo',  //查询条件
      "purchaseCartCreate", //加入采购购物车
    ]),
    ...mapActions("mysaleMudule", [
      "listTreeReq", //获取左边tree数据
      // 'quoteSetUpdate',  //修改非代理设置
      "purchasePage",
    ]),

    filterExportOffer(data) {
      data = JSON.parse(JSON.stringify(data || []));
      data.map((item) => {

        item.qty = item.qty || 1; //数量
        item.manufactureDate =
          item.manufactureDate || moment().format("YYYY-MM-DD"); //生产日期
        item.channel = item.channel || "现货"; //渠道
        item.purchasePrice = item.purchasePrice || ""; //采购单价
        item.profitSite = item.profitSite || ""; //利润点数
        item.guideSite = item.guideSite || ""; //面价点数
        item.guidePrice = (item.guidePrice || item.skuGuidePrice || 0).toFixed(
          2
        );
        item.shipArea =
          typeof item.shipArea === "string"
            ? item.shipArea.split(",")
            : item.shipArea || [];
        item.amount = item.qty * Number(item.salesPrice);
        item.numType = "qty";

        return offerProfitCompute(item);
      });
      this.offerInfoData = data;
      this.offerInfoData.forEach((v) => {
        this.num += v.qty * v.salesPrice;
        // console.log(this.num);
      });
    },

    clickExportOffer(v) {
      let obj =
        this.info.forEnterprise.find(
          (e) => e.sellerEnterpriseId === v.sellerEnterpriseId
        );

      let arr = []
      this.info.forSku.forEach(e => {
        if (Array.isArray(e.enterpriseVOList)) {
          e.enterpriseVOList.forEach(b => {
            if (b.sellerEnterpriseId === v.sellerEnterpriseId) {
              arr.push({ ...e, ...b })

              if (Array.isArray(b.itemList)) {
                b.itemList.forEach(m => {
                  arr.push({ ...e, ...m })

                })
              }
            }


          })
        }
      })

      if (Array.isArray(obj.skuVOList)) {

        this.filterExportOffer(arr);
        this.offerInfoVisible = true;
        this.quoteInfos = { ...obj };

      } else {
        ElMessage({
          type: "warning",
          message: "暂无报价单数据",
        });
      }
    },
    openModal(list) {
      this.gridData = list;
      this.dialogTableVisible = true;
    },
    getRowClass(p1, p2, p3) {
      //
      return p3 === p1 ? "minPrice" : p2 === p1 ? "maxPrice" : "";
    },
    addCart1(row) {
      this.purchaseCartCreate({
        inquiryId: row.inquiryId, //询价单id
        purchasePrice: row.salesPrice, //采购单价(销售价)
        purchaseQty: row.qty, //采购数量
        quoteId: row.quoteId, //报价单id
        sellerId: row.sellerId, //卖家id
        sellerEnterpriseId: row.sellerEnterpriseId,
        skuId: row.skuId,
        quoteItemId: row.itemId,
        remarks: "", //备注
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "添加成功",
          });
          this.addSuccess();
        }
      });
    },
    addCart(row, info, data) {
      //加入询价单
      // info.inquiryId = this.inquiryId;
      // this.activeRow = {row,info};
      // this.changeNumShow = true;
      this.purchaseCartCreate({
        inquiryId: info.inquiryId, //询价单id
        purchasePrice: row.salesPrice, //采购单价(销售价)
        purchaseQty: 1, //采购数量
        quoteId: info.quoteId, //报价单id
        sellerId: data.sellerId, //卖家id
        sellerEnterpriseId: data.sellerEnterpriseId,
        skuId: info.skuId,
        quoteItemId: row.itemId,
        remarks: "", //备注
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "添加成功",
          });
          this.addSuccess();
        }
      });
    },
    init() {
      var data = JSON.parse(JSON.stringify(this.info.forSku || [])),
        company = [];
      //组装数据
      data.map((item) => {
        item.enterpriseVOList.map((v) => {
          var obj = {
            //商品报价信息
            qty: v.qty,
            purchasePrice: v.purchasePrice,
            profitSite: v.profitSite,
            isNewest: v.isNewest,
            guideSite: v.guideSite,
            channel: v.channel,
            quoteTime: v.quoteTime,
            guidePrice: v.guidePrice,
            salesDis: v.salesDis,
            itemList: v.itemList,
            sellerName: v.sellerName,
            salesPrice: v.salesPrice,
            purchaseDis: v.purchaseDis,
            brandId: item.brandId,
            skuId: item.skuId,
            itemId: item.itemId,
          };
          obj = offerProfitCompute(obj); //计算后的价格
          obj.shipArea = v.shipArea;
          if (
            company.some((i) => i.sellerEnterpriseId === v.sellerEnterpriseId)
          ) {
            company[
              company.findIndex(
                (i) => i.sellerEnterpriseId === v.sellerEnterpriseId
              )
            ][item.skuId] = obj;
          } else {
            company.push({
              sellerEnterpriseId: v.sellerEnterpriseId,
              sellerEnterpriseName: v.sellerEnterpriseName,
              sellerId: v.sellerId,
              sellerName: v.sellerName,
              purchasePrice: v.purchasePrice,
              [item.skuId]: obj,
              isAgent: v.isAgent,
              agentList: v.agentList,
            });
          }
        });
      });
      this.data = data;
      this.company = company;
    },
    getTreeList() {
      this.listTreeReq({ type: "DIS_AGENT" }).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          this.treeList = handleTreeData(data, "categoryName");
        }
      });
    },
    getContent() {
      this.purchasePage({
        pageNo: "1",
        pageSize: "999",
        inquiryId: this.inquiryId,
      }).then((res) => {
        this.content = res.data.data.rows;
        this.total = res.data.totalCount;
      });
    },
    changcurrent(val) {
      this.pageNo = val;
      this.dialog = false;
    },
  },
  created() {
    this.init();
    this.getContent();
  },
  watch: {
    info() {
      this.init();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  padding: 20px;
}

.label {
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
}

.a {
  padding: 20px;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}

.o {
  height: 100%;
  overflow-y: auto;
  display: block;

  .o-title {
    padding: 10px 20px 10px 0;
    display: flex;
    justify-content: space-between;

    span {
      color: #d84146;
      font-weight: bold;
      margin: 0 5px;
    }
  }
}

.c {
  box-sizing: border-box;
  padding: 10px;

  .c-item {
    text-align: center;
    display: flex;
    margin-top: 5px;

    span {
      margin-right: 5px;
    }
  }

  .c-btn {
    display: inline-block;
    margin-left: 10px;

    //justify-content: flex-end;
    i {
      font-size: 24px;
      color: #d84146;
      cursor: pointer;
    }
  }
}

.isAgent-text {
  color: #d84146;
  font-weight: 900;
  cursor: pointer;
}

.h-tree-box {
  height: 350px;
  overflow-y: auto;

  .h-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 5px;
    background: #f2f5fa;
  }
}

.minPrice {
  background: #57cc66;
  color: #ffffff;
}

.maxPrice {
  background: #3797d3;
  color: #ffffff;
}

.m-r-cart {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  .c-cart-icon {
    position: relative;
    cursor: pointer;

    i {
      font-size: 30px;
      color: @dh-color;
    }

    .dot {
      position: absolute;
      right: 0;
      top: -6px;
      border-radius: 8px;
      transform: translateX(50%);
      border: 2px solid #ffffff;

      p {
        padding: 0px 4px;
        font-size: 8px;
        color: #ffffff;
        background: @dh-color;
        border-radius: 8px;
      }
    }
  }
}
</style>
