<template>
  <div class="ed">
    <div class="ed-title">
      <div>
        {{ info.inquiryRequestVO.documentText }}
      </div>
    </div>

    <div class="ed-info">
      <div>
        <div class="ed-p">
          <span>询价人:</span>
          <p>
            {{ info.inquiryRequestVO.buyerName }} /
            {{ info.inquiryRequestVO.buyerPhone }} /
            {{ info.inquiryRequestVO.buyerEnterpriseName }}
          </p>
        </div>
        <div class="ed-p">
          <span>最终用户:</span>
          <p>
            {{ info.inquiryRequestVO.inquiryCustomerName }} /
            {{ info.inquiryRequestVO.inquiryCustomerPhone }} /
            {{ info.inquiryRequestVO.inquiryEnterpriseName }}

          </p>
        </div>
      </div>
      <div class="ed-info-i">
        <span>询价单号:</span>
        <p>{{ info.inquiryRequestVO.inquiryNo }}</p>
      </div>
      <div class="ed-info-i">
        <span>制单时间:</span>
        <p>{{ info.inquiryRequestVO.documentTime }}</p>
      </div>

      <div class="ed-info-i">
        <span>链接:</span>
        <p id="copyCode">
          {{ `${protocol}//${host}/login/sharePage?id=${id}&belongType=0` }}
        </p>
      </div>
      <div class="ed-info-i">
        <el-button type="primary" style="height:40px" @click="copy">复制</el-button>

        <el-popover placement="bottom" :width="330" trigger='hover'>
          <template #reference>
            <el-button type="primary" style="height:40px" @click='exportOffer'>下载分享二维码</el-button>
          </template>
          <div class="poster" id='offerBox'  v-if="info.itemRequestVOList">
            <img width="50" src="@/assets/baojialogo.jpg" mode="widthFix" alt="" />
            <div class="posterTitle">{{ info.inquiryRequestVO.buyerEnterpriseName }} {{ info.inquiryRequestVO.buyerName }} 邀请你报价</div>
            <div class='posterBody'>
              <img width="300" src='@/assets/email.png'  mode="widthFix" alt="">
              <div class="posterBodyText">
                <div>{{info.itemRequestVOList.length ? info.itemRequestVOList[0].brandName : '暂无产品信息'}}</div>
                <p v-for="(item,index) in info.itemRequestVOList" :key="index" v-show="index < 2" >{{ item.barCode }} 数量 ： {{ item.skuQty }}</p>
                <div>查看更多 <i class="el-icon-arrow-right"></i></div>
              </div>
              <img class="posterQRCode" width="150" :src="imageUrl" alt="">
              <div class='posterQRText'>微信长按识别二维码 <span>快速报价</span> </div>
              <div class='posterDate'>您通过盘雷报价，保存我向您的询价记录，以便采购时我能找到您</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="line"></div>
    <el-tabs v-model="activeNav">
      <el-tab-pane :label="item.title" :name="item.type" :key="item.type" :disabled="item.disabled"
        v-for="item in navs">
      </el-tab-pane>
    </el-tabs>
    <div class="ed-content">
      <component @bjSuccess="bjSuccess" :info="info" :inquiryId="$route.query.id" :is="comName"></component>
    </div>
  </div>
</template>

<script>
import html2Canvas from 'html2canvas'
import productInfo from "../components/enquiryDetails/productInfo.vue"; //产品信息
import inquiryInfo from "../components/enquiryDetails/inquiryInfo.vue"; //询价信息
import offerInfo1 from "../components/enquiryDetails/offerInfo1.vue"; //报价信息1（指定企业报价）
import offerInfo2 from "../components/enquiryDetails/offerInfo2.vue"; //报价信息2（全平台报价）
import editOfferInfo from "../../mysaleMudule/components/enquiryDetails/editOfferInfo.vue"; //编辑报价信息
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      navs: [
        { title: "询价信息", type: 3, name: "inquiryInfo" },
        { title: "产品信息", type: 2, name: "productInfo" },
      ],
      activeNav: 3,
      info: {
        inquiryRequestVO: {
          address: {},
        },
      },
      type: "my", //my我的询价详情 customer 客户询价详情(默认我的询价详情)
      id: "",
      protocol: window.location.protocol,
      host: window.location.host,
      userType: "",
      imageUrl: ''
    };
  },
  components: {
    productInfo,
    inquiryInfo,
    offerInfo1,
    offerInfo2,
    editOfferInfo,
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "inquiryInfo", //获取询价详情
      "invitation"
    ]),
    downs(url) {
      var alink = document.createElement("a");
      alink.href = url;
      alink.download = "pic"; //图片名
      alink.click();
    },

    exportOffer() {
      var that = this
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      const imageWrapper = document.querySelector('#offerBox')
      html2Canvas(imageWrapper, {
        userCORS: true,
        dpi: 300, // 图片清晰度问题
        background: '#FFFFFF',
        scrollY: 0,
        scrollX: 0,
      }).then(function (canvas) {
        that.downs(canvas.toDataURL('image/jpeg', 1.0))
      })
    },
    imageUrlToBase64(src) {
      var that = this
      let image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src =  src 
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        that.imageUrl = canvas.toDataURL("image/jpeg")
      }
    },
    getQrCode() {
      this.invitation({
        type: "INQUIRY",
        inquiryId: this.$route.query.id,
        url: `/conpoment/xunjiaxq?title=报价详情&id=${this.$route.query.id}&type=3&share=1&shareUserId=${JSON.parse(localStorage.getItem('userInfo')).userId}&shareType=${JSON.parse(localStorage.getItem('userInfo')).userType}`
      }).then((res) => {
        this.imageUrlToBase64(res.data.data)
      })
  },
  copy() {
    const range = document.createRange()
    range.selectNode(document.getElementById('copyCode'))
    const selection = window.getSelection()
    if (selection.rangeCount > 0) selection.removeAllRanges()
    selection.addRange(range)
    document.execCommand('copy')
    this.$message({
      message: '复制成功',
      type: 'success'
    })
    selection.removeRange(range)
  },
  getDetails() {
    //获取
    this.getQrCode()
    this.inquiryInfo({
      inquiryId: this.$route.query.id,
    }).then((res) => {
      let { code, data } = res.data;
      if (code === "0") {
        //data.inquiryRequestVO.documentTime  = moment(data.inquiryRequestVO.documentTime).format('YYYY.MM.DD hh:mm:ss');
        data.inquiryRequestVO.priceUnit = this.getType(
          "moneys",
          data.inquiryRequestVO.priceUnit
        );
        data.inquiryRequestVO.projectFile = JSON.parse(
          data.inquiryRequestVO.projectFile || "[]"
        );
        data.inquiryRequestVO.documentText =
          this.documentState[data.inquiryRequestVO.documentState];
        data.inquiryRequestVO.file = JSON.parse(
          data.inquiryRequestVO.file || "[]"
        );
        data.inquiryRequestVO.projectState = data.inquiryRequestVO
          .projectState
          ? this.getType("projectState", data.inquiryRequestVO.projectState)
          : "";
        data.inquiryRequestVO.paymentType = this.getType(
          "paymentTypes",
          data.inquiryRequestVO.paymentType
        );
        if (data.forEnterprise || data.forSku || this.type === "customer") {
          if (!this.navs.some((v) => v.type === 1)) {
            this.activeNav = 1;
            this.navs.push({
              title: "报价信息",
              type: 1,
              name: "offerInfo1",
            });
          }
          if (this.type === "customer") {
            this.navs[this.matchingIndex(1)].name = "editOfferInfo"; //编辑报价信息
          } else if (data.inquiryRequestVO.isPlatform === "1") {
            //全平台报价
            this.navs[this.matchingIndex(1)].name = "offerInfo2";
          } else {
            //指定企业报价
            this.navs[this.matchingIndex(1)].name = "offerInfo1";
          }
        }
        this.info = data; //商品列表
      }
    });
  },
  getType(attribute, type) {
    return type
      ? attribute === "paymentTypes" &&
        !this[attribute].filter((v) => v.type === type).length
        ? this[attribute][0].title
        : this[attribute].filter((v) => v.type === type).length
          ? this[attribute].filter((v) => v.type === type)[0].title
          : ""
      : "";
  },
  bjSuccess() {
    //报价成功刷新页面信息
    this.getDetails();
  },
  matchingIndex(type) {
    return this.navs.findIndex((v) => v.type === type);
  },
},
computed: {
    ...mapState("purchaseMudule", [
  "paymentTypes",
  "protects",
  "moneys",
  "documentState",
  "projectState",
]),
    comName() {
    //组件名字
    return this.navs.filter((v) => this.activeNav === v.type)[0].name;
  },
},
created() {
  console.log(this.$store.state);
  if (this.$route.query.type) {
    this.type = this.$route.query.type;
  }
  this.getDetails();
  this.id = this.$route.query.id;
},
};
</script>

<style scoped lang="less">
.ed {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .ed-info {
    display: flex;
    padding-bottom: 15px;

    .ed-info-i {
      font-size: 14px;
      display: flex;
      flex: 1;

      span {
        color: #999999;
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        flex: 1;
        padding-right: 40px;
      }
    }

    .ed-p {
      span {
        color: #999999;
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        flex: 1;
        padding-right: 40px;
      }
    }
  }

  .ed-title {
    padding-bottom: 5px;
    color: @dh-color;
    font-weight: bold;
    line-height: 30px;
  }

  .line {
    height: 15px;
    background: #f2f5fa;
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
  }

  .ed-content {
    flex: 1;
    overflow-y: auto;
  }
}

.poster {
  padding: 15px 15px 30px;
  width: 300px;
  display: flex;
  flex-direction: column;

  .posterTitle {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 20px 0;
  }

  .posterBody {
    width: 300px;
    position: relative;

    .posterBodyText {
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      width: 300px;

      p {
        font-size: 12px;
        padding-top: 5px;
      }

      div {
        font-size: 14px;
        font-weight: bold;
        color: #000;

        &:nth-last-child(1) {
          padding-top: 20px;
        }
      }
    }
  }

  .posterQRCode {
    margin: 20px auto;
  }

  .posterQRText {

    text-align: center;

    span {

      font-weight: bold;
    }
  }

  .posterDate {
    text-align: center;
    color: #000;
    font-weight: bold;
    padding-top: 10px;
  }
}
</style>
