<template>
	<el-dialog
	    width="600px"
		:destroy-on-close="true"
		@open="open"
	  >
		<div class="a">
			<div class="a-title">加入采购单</div>
			<div class="a-item">
				<span>报价时间:</span><p>{{data.documentTime}}</p>
			</div>
			<div class="a-item">
				<span>生成日期:</span><p>{{data.manufactureDate}}</p>
			</div>
			<div class="a-item">
				<span>发货地址:</span><p>{{data.shipArea}}</p>
			</div>
			<div class="a-item">
				<span>渠道:</span><p>{{data.channel}}</p>
			</div>
			<div class="a-item">
				<span>数量:</span><p>{{data.qty}}</p>
			</div>
			<div class="a-item">
				<span>采购数量:</span><el-input-number v-model="data.purchaseQty" :min="1" :max="9999" />
			</div>
			<div class="a-footer">
				<el-button size="mini" style="margin-right: 20px;" @click="close">取消</el-button>
				<el-button size="mini" style="margin-left: 20px;" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions } from "vuex"
	import { ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				obj:{
					row:{},
					info:{},
				},
				data:{},
			}
		},
		props:['info'],
		methods:{
			...mapActions('purchaseMudule',[
				'inquiryPurchaseInfo',  //查询条件
				'purchaseCartCreate',  //加入采购购物车
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			confirm(){ //确认的点击事件
				this.purchaseCartCreate({
					inquiryId:this.data.inquiryId,  //询价单id
					purchasePrice:this.data.purchasePrice,  //采购单价
					purchaseQty:this.data.purchaseQty ,  //采购数量
					quoteId:this.data.quoteId, //报价单id
					sellerId:this.data.sellerId,  //卖家id
					sellerEnterpriseId:this.data.sellerEnterpriseId,
					skuId:this.data.skuId,
					quoteItemId:this.obj.row.itemId,
					remarks:'',  //备注
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						ElMessage({
							type:'success',
							message:'添加成功',
						})
						this.$emit('success');
						this.close();
					}
				})
			},
			open(){ //打开
				this.getInfo();
			},
			getInfo(){ //获取信息
				this.inquiryPurchaseInfo({
					quoteId:this.obj.info.quoteId,
					skuId:this.obj.row.skuId,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.purchaseQty = 1;
						this.data = data;
					}
				})
			}
		},
		created(){
			
		},
		watch:{
			info(v){
				this.obj = JSON.parse(JSON.stringify(v));
			},
		}
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px 40px;
		.a-title{
			font-weight: bold;
			padding-bottom: 20px;
		}
		.a-item{
			display: flex;
			align-items: center;
			padding-bottom: 10px;
			span{
				width: 88px;
				text-align: right;
				margin-right: 20px;
			}
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
</style>
