<template>
  <div class="o">
    <div class="o-title">
      <div class="o-t-left">
        总报价数量<span>{{ data.length }}</span
        >个
      </div>
      <div style="position:absolute;left:10%;">
        <el-button style="margin-right: 16px" @click="dialog = true"
          >查看所有报价详情</el-button
        >
        <el-dialog v-model="dialog">
          <div class="a">
            <div class="a-title">查看所有报价详情</div>
            <div style="height:1000px;overflow:auto">
              <el-table :data="content">
                <el-table-column type="index" width="55" label="序号" />
                <el-table-column property="documentDate" label="时间" />
                <el-table-column
                  property="sellerEnterpriseName"
                  label="被采购公司"
                />
                <el-table-column property="contractNum" label="型号" />
                <el-table-column property="purchaseQty" label="采购数量" />
              </el-table>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- v-if="info.inquiryRequestVO.documentState === 'COMPLETE_QUOTE'" -->
      <div class="m-r-cart" @click="showPurchase">
        <div class="c-cart-icon">
          <i class="iconfont icon-gouwuche"></i>
          <div class="dot" v-if="cartGoods.length">
            <p>{{ cartGoods.length }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-collapse>
      <el-collapse-item
        :name="index"
        v-for="(item, index) in data"
        :key="index"
      >
        <!-- <template #title>
          <div>{{ item.sellerEnterpriseName }}</div>
        </template> -->
        <template #title>
          <div class="c-t-head-box">
            {{ item.sellerEnterpriseName }}
            <!-- <span v-if="item.agentList&&item.agentList.length" class="isAgent-text">Ⅴ</span> -->
            <el-popover
              v-if="item.agentList && item.agentList.length"
              placement="right"
              width="400"
              trigger="click"
            >
              <div class="h-tree-box">
                <p class="h-title">代理品牌</p>
                <!-- <el-tree
                  :data="item.agentList"
                  default-expand-all
                  node-key="brandName"
                  ref="tree"
                /> -->
                <p v-for="(v, i) in item.agentList" :key="i">
                  {{ v.brandName }}
                </p>
              </div>
              <template #reference>
                <span
                  v-if="item.agentList && item.agentList.length"
                  class="isAgent-text"
                  style="color: #d84146;font-weight: 900;cursor: pointer;"
                  >Ⅴ</span
                >
              </template>
            </el-popover>
          </div>
        </template>
        <lida-table
          :pagination="false"
          :height="300"
          :data="item.skuVOList"
          border
          ref="table"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
            fixed
          />
          <lida-table-column label="产品信息" fixed>
            <template #default="scope">
              <div class="g-info">
                <div class="g-icon">
                  <img :src="scope.row.src" />
                </div>
                <div class="g-right">
                  <div class="g-r-title">
                    {{ scope.row.productName }}
                    <div class="label" :title="scope.row.brandName">
                      {{ scope.row.brandName }}
                    </div>
                  </div>
                  <div class="g-r-data">
                    {{ scope.row.barCode || "无" }}
                    <span>|</span>
                    {{ scope.row.seriesName || "无" }}
                    <span>|</span>
                    {{ scope.row.skuNumber || "无" }}
                  </div>
                </div>
              </div>
            </template>
          </lida-table-column>
          <lida-table-column label="订货号" prop="articleNo" />
          <lida-table-column label="面价" prop="guidePrice">
            <template #default="scope">
              <div>￥{{ scope.row.guidePrice }}</div>
            </template>
          </lida-table-column>
          <lida-table-column
            label="报价价格"
            prop="purchasePrice"
            v-if="item.sellerId === $store?.state?.userInfo?.userId"
          >
            <template #default="scope">
              <div>￥{{ scope.row.purchasePrice }}</div>
            </template>
          </lida-table-column>
          <lida-table-column label="报价数量" prop="qty" />
          <lida-table-column label="销售价格">
            <template #default="scope">
              <div>￥{{ scope.row.salesPrice }}</div>
            </template>
          </lida-table-column>
          <lida-table-column label="发货地址">
            <template #default="scope">
              <div>{{ scope.row.shipArea }}</div>
            </template>
          </lida-table-column>
          <lida-table-column label="渠道">
            <template #default="scope">
              <div>{{ scope.row.channel }}</div>
            </template>
          </lida-table-column>
          <lida-table-column label="是否过保" prop="isExpire" />
          <lida-table-column label="操作" fixed="right" :min-width="100">
            <!-- v-if="info.inquiryRequestVO.documentState === 'COMPLETE_QUOTE'" -->
            <template #default="scope">
              <el-button
                size="mini"
                @click="addCart(scope.row, item)"
                type="text"
                >加入采购单</el-button
              >
            </template>
          </lida-table-column>
        </lida-table>
      </el-collapse-item>
    </el-collapse>
  </div>
  <!-- 添加到采购购物车弹框 -->
  <add-cart-change-num
    v-model="changeNumShow"
    @success="addSuccess"
    :info="activeRow"
  />
  <!-- 采购购物车 -->
  <add-purchase-order
    v-model="purchaseCartShow"
    :data="cartGoods"
    @delete="deleteCart"
    @success="createSuccess"
    :inquiryId="inquiryId"
  />
</template>

<script>
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import purchaseCartMixins from "../../mixins/purchaseCart.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      data: [],
      content: [],
      total: 0,
      dialog: false,
    };
  },
  props: ["info", "inquiryId"],
  mixins: [purchaseCartMixins],
  methods: {
    ...mapActions("mysaleMudule", ["purchasePage"]),
    addCart(row, info) {
      //加入询价单
      info.inquiryId = this.inquiryId;
      this.activeRow = { row, info };
      this.changeNumShow = true;
    },
    init() {
      var data = JSON.parse(JSON.stringify(this.info.forEnterprise || []));
      data.map((item) => {
        item.skuVOList.map((son) => {
          return offerProfitCompute(son);
        });
      });
      this.data = data;
    },
    getContent() {
      this.purchasePage({ pageNo: "1", pageSize: "999",inquiryId:this.inquiryId }).then((res) => {
        this.content = res.data.data.rows;
        this.total = res.data.totalCount;
      });
    },
  },
  created() {
    this.init();
    this.getContent();
  },
  watch: {
    info() {
      this.init();
    },
  },
};
</script>

<style scoped lang="less">
.a {
  padding: 20px;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}
.o {
  height: 100%;
  overflow-y: auto;
  display: block;
  .o-title {
    padding: 10px 20px 10px 0;
    display: flex;
    justify-content: space-between;
    span {
      color: #d84146;
      font-weight: bold;
      margin: 0 5px;
    }
  }
}
.m-r-cart {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  .c-cart-icon {
    position: relative;
    cursor: pointer;
    i {
      font-size: 30px;
      color: @dh-color;
    }
    .dot {
      position: absolute;
      right: 0;
      top: -6px;
      border-radius: 8px;
      transform: translateX(50%);
      border: 2px solid #ffffff;
      p {
        padding: 0px 4px;
        font-size: 8px;
        color: #ffffff;
        background: @dh-color;
        border-radius: 8px;
      }
    }
  }
  .isAgent-text {
    color: #d84146;
    font-weight: 900;
    cursor: pointer;
  }
}
</style>
